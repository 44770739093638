// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-blockchain-marketing-news-js": () => import("./../../../src/pages/resources/blockchain-marketing-news.js" /* webpackChunkName: "component---src-pages-resources-blockchain-marketing-news-js" */),
  "component---src-pages-resources-guides-js": () => import("./../../../src/pages/resources/guides.js" /* webpackChunkName: "component---src-pages-resources-guides-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-resource-detail-js": () => import("./../../../src/templates/resource-detail.js" /* webpackChunkName: "component---src-templates-resource-detail-js" */),
  "component---src-templates-service-detail-js": () => import("./../../../src/templates/service-detail.js" /* webpackChunkName: "component---src-templates-service-detail-js" */)
}

